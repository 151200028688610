import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import styled from "styled-components";
import "../index.css";
import { emailSchema } from "./UserEmailValidation";
import * as dotenv from "dotenv";

//import pictures
import unlabeledArrows from "../assets/images/Frames/HelperArrows.png";
import largeThumbArrows from "../assets/images/Frames/HelperArrowsThumbs.png";
import poweredByStripe from "../assets/images/ads/Powered_by_Stripe_black.svg";

/*frames*/
import blackFrame from "../assets/images/Frames/0_frame_black.PNG";
import darkGreyFrame from "../assets/images/Frames/0_frame_darkGrey.PNG";
import lightGreyFrame from "../assets/images/Frames/0_frame_lightGrey.PNG";
import whiteFrame from "../assets/images/Frames/0_frame_white.PNG";

/*framesAndSwitches*/
import blackFrameBlueSwitch from "../assets/images/Frames/0_frame_black_blueSwitch.png";
import darkGreyFrameBlueSwitch from "../assets/images/Frames/0_frame_darkGrey_blueSwitch.png";
import lightGreyFrameBlueSwitch from "../assets/images/Frames/0_frame_lightGrey_blueSwitch.png";
import whiteFrameBlueSwitch from "../assets/images/Frames/0_frame_white_blueSwitch.png";
import blackFrameBrownSwitch from "../assets/images/Frames/0_frame_black_brownSwitch.png";
import darkGreyFrameBrownSwitch from "../assets/images/Frames/0_frame_darkGrey_brownSwitch.png";
import lightGreyFrameBrownSwitch from "../assets/images/Frames/0_frame_lightGrey_brownSwitch.png";
import whiteFrameBrownSwitch from "../assets/images/Frames/0_frame_white_brownSwitch.png";
import blackThumbnail from "../assets/images/Frames/blackFrameThumbnail.png";
import darkGreyThumbnail from "../assets/images/Frames/darkGreyFrameThumbnail.png";
import lightGreyThumbnail from "../assets/images/Frames/lightGreyFrameThumbnail.png";
import whiteThumbnail from "../assets/images/Frames/whiteFrameThumbnail.png";
import blueSwitchThumbnail from "../assets/images/Frames/blueSwitchThumbnail.png";
import brownSwitchThumbnail from "../assets/images/Frames/brownSwitchThumbnail.png";

/*labeled*/
import keyTextBlackPic from "../assets/images/Key5/5_text_black_text.png";
import keyTextBlackThumbnail from "../assets/images/Key5/blackKeyCapThumbnail.png";
import keyTextWhitePic from "../assets/images/Key5/5_text_white.PNG";
import keyTextWhiteThumbnail from "../assets/images/Key5/whiteKeyCapThumbnail.png";
import keyTextLightGreyPic from "../assets/images/Key5/5_text_lightGrey.PNG";
import keyTextLightGreyThumbnail from "../assets/images/Key5/lightGreyKeyCapThumbnail.png";

/*unlabeled*/
import oneLRed from "../assets/images/Key1/1_L_red.png";
import oneLOrange from "../assets/images/Key1/1_L_orange.png";
import oneLYellow from "../assets/images/Key1/1_L_yellow.png";
import oneLGreen from "../assets/images/Key1/1_L_green.png";
import oneLCyan from "../assets/images/Key1/1_L_cyan.png";
import oneLBlue from "../assets/images/Key1/1_L_blue.png";
import oneLPurple from "../assets/images/Key1/1_L_purple.png";
import oneLPink from "../assets/images/Key1/1_L_pink.png";
import oneLBlack from "../assets/images/Key1/1_L_black.png";
import oneLDarkGrey from "../assets/images/Key1/1_L_darkGrey.png";
import oneLLightGrey from "../assets/images/Key1/1_L_lightGrey.png";
import oneLWhite from "../assets/images/Key1/1_L_white.png";
import twoLRed from "../assets/images/Key2/2_L_red.png";
import twoLOrange from "../assets/images/Key2/2_L_orange.png";
import twoLYellow from "../assets/images/Key2/2_L_yellow.png";
import twoLGreen from "../assets/images/Key2/2_L_green.png";
import twoLCyan from "../assets/images/Key2/2_L_cyan.png";
import twoLBlue from "../assets/images/Key2/2_L_blue.png";
import twoLPurple from "../assets/images/Key2/2_L_purple.png";
import twoLPink from "../assets/images/Key2/2_L_pink.png";
import twoLBlack from "../assets/images/Key2/2_L_black.png";
import twoLDarkGrey from "../assets/images/Key2/2_L_darkGrey.png";
import twoLLightGrey from "../assets/images/Key2/2_L_lightGrey.png";
import twoLWhite from "../assets/images/Key2/2_L_white.png";
import threeLRed from "../assets/images/Key3/3_L_red.png";
import threeLOrange from "../assets/images/Key3/3_L_orange.png";
import threeLYellow from "../assets/images/Key3/3_L_yellow.png";
import threeLGreen from "../assets/images/Key3/3_L_green.png";
import threeLCyan from "../assets/images/Key3/3_L_cyan.png";
import threeLBlue from "../assets/images/Key3/3_L_blue.png";
import threeLPurple from "../assets/images/Key3/3_L_purple.png";
import threeLPink from "../assets/images/Key3/3_L_pink.png";
import threeLBlack from "../assets/images/Key3/3_L_black.png";
import threeLDarkGrey from "../assets/images/Key3/3_L_darkGrey.png";
import threeLLightGrey from "../assets/images/Key3/3_L_lightGrey.png";
import threeLWhite from "../assets/images/Key3/3_L_white.png";
import fourLRed from "../assets/images/Key4/4_L_red.png";
import fourLOrange from "../assets/images/Key4/4_L_orange.png";
import fourLYellow from "../assets/images/Key4/4_L_yellow.png";
import fourLGreen from "../assets/images/Key4/4_L_green.png";
import fourLCyan from "../assets/images/Key4/4_L_cyan.png";
import fourLBlue from "../assets/images/Key4/4_L_blue.png";
import fourLPurple from "../assets/images/Key4/4_L_purple.png";
import fourLPink from "../assets/images/Key4/4_L_pink.png";
import fourLBlack from "../assets/images/Key4/4_L_black.png";
import fourLDarkGrey from "../assets/images/Key4/4_L_darkGrey.png";
import fourLLightGrey from "../assets/images/Key4/4_L_lightGrey.png";
import fourLWhite from "../assets/images/Key4/4_L_white.png";
import tenLRed from "../assets/images/Key10/10_L_red.png";
import tenLOrange from "../assets/images/Key10/10_L_orange.png";
import tenLYellow from "../assets/images/Key10/10_L_yellow.png";
import tenLGreen from "../assets/images/Key10/10_L_green.png";
import tenLCyan from "../assets/images/Key10/10_L_cyan.png";
import tenLBlue from "../assets/images/Key10/10_L_blue.png";
import tenLPurple from "../assets/images/Key10/10_L_purple.png";
import tenLPink from "../assets/images/Key10/10_L_pink.png";
import tenLBlack from "../assets/images/Key10/10_L_black.png";
import tenLDarkGrey from "../assets/images/Key10/10_L_darkGrey.png";
import tenLLightGrey from "../assets/images/Key10/10_L_lightGrey.png";
import tenLWhite from "../assets/images/Key10/10_L_white.png";
import elevenLRed from "../assets/images/Key11/11_L_red.png";
import elevenLOrange from "../assets/images/Key11/11_L_orange.png";
import elevenLYellow from "../assets/images/Key11/11_L_yellow.png";
import elevenLGreen from "../assets/images/Key11/11_L_green.png";
import elevenLCyan from "../assets/images/Key11/11_L_cyan.png";
import elevenLBlue from "../assets/images/Key11/11_L_blue.png";
import elevenLPurple from "../assets/images/Key11/11_L_purple.png";
import elevenLPink from "../assets/images/Key11/11_L_pink.png";
import elevenLBlack from "../assets/images/Key11/11_L_black.png";
import elevenLDarkGrey from "../assets/images/Key11/11_L_darkGrey.png";
import elevenLLightGrey from "../assets/images/Key11/11_L_lightGrey.png";
import elevenLWhite from "../assets/images/Key11/11_L_white.png";
import fourteenLRed from "../assets/images/Key14/14_L_red.png";
import fourteenLOrange from "../assets/images/Key14/14_L_orange.png";
import fourteenLYellow from "../assets/images/Key14/14_L_yellow.png";
import fourteenLGreen from "../assets/images/Key14/14_L_green.png";
import fourteenLCyan from "../assets/images/Key14/14_L_cyan.png";
import fourteenLBlue from "../assets/images/Key14/14_L_blue.png";
import fourteenLPurple from "../assets/images/Key14/14_L_purple.png";
import fourteenLPink from "../assets/images/Key14/14_L_pink.png";
import fourteenLBlack from "../assets/images/Key14/14_L_black.png";
import fourteenLDarkGrey from "../assets/images/Key14/14_L_darkGrey.png";
import fourteenLLightGrey from "../assets/images/Key14/14_L_lightGrey.png";
import fourteenLWhite from "../assets/images/Key14/14_L_white.png";
import fifteenLRed from "../assets/images/Key15/15_L_red.png";
import fifteenLOrange from "../assets/images/Key15/15_L_orange.png";
import fifteenLYellow from "../assets/images/Key15/15_L_yellow.png";
import fifteenLGreen from "../assets/images/Key15/15_L_green.png";
import fifteenLCyan from "../assets/images/Key15/15_L_cyan.png";
import fifteenLBlue from "../assets/images/Key15/15_L_blue.png";
import fifteenLPurple from "../assets/images/Key15/15_L_purple.png";
import fifteenLPink from "../assets/images/Key15/15_L_pink.png";
import fifteenLBlack from "../assets/images/Key15/15_L_black.png";
import fifteenLDarkGrey from "../assets/images/Key15/15_L_darkGrey.png";
import fifteenLLightGrey from "../assets/images/Key15/15_L_lightGrey.png";
import fifteenLWhite from "../assets/images/Key15/15_L_white.png";
import sixteenLRed from "../assets/images/Key16/16_L_red.png";
import sixteenLOrange from "../assets/images/Key16/16_L_orange.png";
import sixteenLYellow from "../assets/images/Key16/16_L_yellow.png";
import sixteenLGreen from "../assets/images/Key16/16_L_green.png";
import sixteenLCyan from "../assets/images/Key16/16_L_cyan.png";
import sixteenLBlue from "../assets/images/Key16/16_L_blue.png";
import sixteenLPurple from "../assets/images/Key16/16_L_purple.png";
import sixteenLPink from "../assets/images/Key16/16_L_pink.png";
import sixteenLBlack from "../assets/images/Key16/16_L_black.png";
import sixteenLDarkGrey from "../assets/images/Key16/16_L_darkGrey.png";
import sixteenLLightGrey from "../assets/images/Key16/16_L_lightGrey.png";
import sixteenLWhite from "../assets/images/Key16/16_L_white.png";
import seventeenLRed from "../assets/images/Key17/17_L_red.png";
import seventeenLOrange from "../assets/images/Key17/17_L_orange.png";
import seventeenLYellow from "../assets/images/Key17/17_L_yellow.png";
import seventeenLGreen from "../assets/images/Key17/17_L_green.png";
import seventeenLCyan from "../assets/images/Key17/17_L_cyan.png";
import seventeenLBlue from "../assets/images/Key17/17_L_blue.png";
import seventeenLPurple from "../assets/images/Key17/17_L_purple.png";
import seventeenLPink from "../assets/images/Key17/17_L_pink.png";
import seventeenLBlack from "../assets/images/Key17/17_L_black.png";
import seventeenLDarkGrey from "../assets/images/Key17/17_L_darkGrey.png";
import seventeenLLightGrey from "../assets/images/Key17/17_L_lightGrey.png";
import seventeenLWhite from "../assets/images/Key17/17_L_white.png";
import oneRRed from "../assets/images/Key1/1_R_red.png";
import oneROrange from "../assets/images/Key1/1_R_orange.png";
import oneRYellow from "../assets/images/Key1/1_R_yellow.png";
import oneRGreen from "../assets/images/Key1/1_R_green.png";
import oneRCyan from "../assets/images/Key1/1_R_cyan.png";
import oneRBlue from "../assets/images/Key1/1_R_blue.png";
import oneRPurple from "../assets/images/Key1/1_R_purple.png";
import oneRPink from "../assets/images/Key1/1_R_pink.png";
import oneRBlack from "../assets/images/Key1/1_R_black.png";
import oneRDarkGrey from "../assets/images/Key1/1_R_darkGrey.png";
import oneRLightGrey from "../assets/images/Key1/1_R_lightGrey.png";
import oneRWhite from "../assets/images/Key1/1_R_white.png";
import twoRRed from "../assets/images/Key2/2_R_red.png";
import twoROrange from "../assets/images/Key2/2_R_orange.png";
import twoRYellow from "../assets/images/Key2/2_R_yellow.png";
import twoRGreen from "../assets/images/Key2/2_R_green.png";
import twoRCyan from "../assets/images/Key2/2_R_cyan.png";
import twoRBlue from "../assets/images/Key2/2_R_blue.png";
import twoRPurple from "../assets/images/Key2/2_R_purple.png";
import twoRPink from "../assets/images/Key2/2_R_pink.png";
import twoRBlack from "../assets/images/Key2/2_R_black.png";
import twoRDarkGrey from "../assets/images/Key2/2_R_darkGrey.png";
import twoRLightGrey from "../assets/images/Key2/2_R_lightGrey.png";
import twoRWhite from "../assets/images/Key2/2_R_white.png";
import threeRRed from "../assets/images/Key3/3_R_red.png";
import threeROrange from "../assets/images/Key3/3_R_orange.png";
import threeRYellow from "../assets/images/Key3/3_R_yellow.png";
import threeRGreen from "../assets/images/Key3/3_R_green.png";
import threeRCyan from "../assets/images/Key3/3_R_cyan.png";
import threeRBlue from "../assets/images/Key3/3_R_blue.png";
import threeRPurple from "../assets/images/Key3/3_R_purple.png";
import threeRPink from "../assets/images/Key3/3_R_pink.png";
import threeRBlack from "../assets/images/Key3/3_R_black.png";
import threeRDarkGrey from "../assets/images/Key3/3_R_darkGrey.png";
import threeRLightGrey from "../assets/images/Key3/3_R_lightGrey.png";
import threeRWhite from "../assets/images/Key3/3_R_white.png";
import fourRRed from "../assets/images/Key4/4_R_red.png";
import fourROrange from "../assets/images/Key4/4_R_orange.png";
import fourRYellow from "../assets/images/Key4/4_R_yellow.png";
import fourRGreen from "../assets/images/Key4/4_R_green.png";
import fourRCyan from "../assets/images/Key4/4_R_cyan.png";
import fourRBlue from "../assets/images/Key4/4_R_blue.png";
import fourRPurple from "../assets/images/Key4/4_R_purple.png";
import fourRPink from "../assets/images/Key4/4_R_pink.png";
import fourRBlack from "../assets/images/Key4/4_R_black.png";
import fourRDarkGrey from "../assets/images/Key4/4_R_darkGrey.png";
import fourRLightGrey from "../assets/images/Key4/4_R_lightGrey.png";
import fourRWhite from "../assets/images/Key4/4_R_white.png";
import tenRRed from "../assets/images/Key10/10_R_red.png";
import tenROrange from "../assets/images/Key10/10_R_orange.png";
import tenRYellow from "../assets/images/Key10/10_R_yellow.png";
import tenRGreen from "../assets/images/Key10/10_R_green.png";
import tenRCyan from "../assets/images/Key10/10_R_cyan.png";
import tenRBlue from "../assets/images/Key10/10_R_blue.png";
import tenRPurple from "../assets/images/Key10/10_R_purple.png";
import tenRPink from "../assets/images/Key10/10_R_pink.png";
import tenRBlack from "../assets/images/Key10/10_R_black.png";
import tenRDarkGrey from "../assets/images/Key10/10_R_darkGrey.png";
import tenRLightGrey from "../assets/images/Key10/10_R_lightGrey.png";
import tenRWhite from "../assets/images/Key10/10_R_white.png";
import elevenRRed from "../assets/images/Key11/11_R_red.png";
import elevenROrange from "../assets/images/Key11/11_R_orange.png";
import elevenRYellow from "../assets/images/Key11/11_R_yellow.png";
import elevenRGreen from "../assets/images/Key11/11_R_green.png";
import elevenRCyan from "../assets/images/Key11/11_R_cyan.png";
import elevenRBlue from "../assets/images/Key11/11_R_blue.png";
import elevenRPurple from "../assets/images/Key11/11_R_purple.png";
import elevenRPink from "../assets/images/Key11/11_R_pink.png";
import elevenRBlack from "../assets/images/Key11/11_R_black.png";
import elevenRDarkGrey from "../assets/images/Key11/11_R_darkGrey.png";
import elevenRLightGrey from "../assets/images/Key11/11_R_lightGrey.png";
import elevenRWhite from "../assets/images/Key11/11_R_white.png";
import fourteenRRed from "../assets/images/Key14/14_R_red.png";
import fourteenROrange from "../assets/images/Key14/14_R_orange.png";
import fourteenRYellow from "../assets/images/Key14/14_R_yellow.png";
import fourteenRGreen from "../assets/images/Key14/14_R_green.png";
import fourteenRCyan from "../assets/images/Key14/14_R_cyan.png";
import fourteenRBlue from "../assets/images/Key14/14_R_blue.png";
import fourteenRPurple from "../assets/images/Key14/14_R_purple.png";
import fourteenRPink from "../assets/images/Key14/14_R_pink.png";
import fourteenRBlack from "../assets/images/Key14/14_R_black.png";
import fourteenRDarkGrey from "../assets/images/Key14/14_R_darkGrey.png";
import fourteenRLightGrey from "../assets/images/Key14/14_R_lightGrey.png";
import fourteenRWhite from "../assets/images/Key14/14_R_white.png";
import fifteenRRed from "../assets/images/Key15/15_R_red.png";
import fifteenROrange from "../assets/images/Key15/15_R_orange.png";
import fifteenRYellow from "../assets/images/Key15/15_R_yellow.png";
import fifteenRGreen from "../assets/images/Key15/15_R_green.png";
import fifteenRCyan from "../assets/images/Key15/15_R_cyan.png";
import fifteenRBlue from "../assets/images/Key15/15_R_blue.png";
import fifteenRPurple from "../assets/images/Key15/15_R_purple.png";
import fifteenRPink from "../assets/images/Key15/15_R_pink.png";
import fifteenRBlack from "../assets/images/Key15/15_R_black.png";
import fifteenRDarkGrey from "../assets/images/Key15/15_R_darkGrey.png";
import fifteenRLightGrey from "../assets/images/Key15/15_R_lightGrey.png";
import fifteenRWhite from "../assets/images/Key15/15_R_white.png";
import sixteenRRed from "../assets/images/Key16/16_R_red.png";
import sixteenROrange from "../assets/images/Key16/16_R_orange.png";
import sixteenRYellow from "../assets/images/Key16/16_R_yellow.png";
import sixteenRGreen from "../assets/images/Key16/16_R_green.png";
import sixteenRCyan from "../assets/images/Key16/16_R_cyan.png";
import sixteenRBlue from "../assets/images/Key16/16_R_blue.png";
import sixteenRPurple from "../assets/images/Key16/16_R_purple.png";
import sixteenRPink from "../assets/images/Key16/16_R_pink.png";
import sixteenRBlack from "../assets/images/Key16/16_R_black.png";
import sixteenRDarkGrey from "../assets/images/Key16/16_R_darkGrey.png";
import sixteenRLightGrey from "../assets/images/Key16/16_R_lightGrey.png";
import sixteenRWhite from "../assets/images/Key16/16_R_white.png";
import seventeenRRed from "../assets/images/Key17/17_R_red.png";
import seventeenROrange from "../assets/images/Key17/17_R_orange.png";
import seventeenRYellow from "../assets/images/Key17/17_R_yellow.png";
import seventeenRGreen from "../assets/images/Key17/17_R_green.png";
import seventeenRCyan from "../assets/images/Key17/17_R_cyan.png";
import seventeenRBlue from "../assets/images/Key17/17_R_blue.png";
import seventeenRPurple from "../assets/images/Key17/17_R_purple.png";
import seventeenRPink from "../assets/images/Key17/17_R_pink.png";
import seventeenRBlack from "../assets/images/Key17/17_R_black.png";
import seventeenRDarkGrey from "../assets/images/Key17/17_R_darkGrey.png";
import seventeenRLightGrey from "../assets/images/Key17/17_R_lightGrey.png";
import seventeenRWhite from "../assets/images/Key17/17_R_white.png";
import twelveLBlack from "../assets/images/Key12/12_L_black.png";
import twelveLLightGrey from "../assets/images/Key12/12_L_lightGrey.png";
import twelveLDarkGrey from "../assets/images/Key12/12_L_darkGrey.png";
import twelveLWhite from "../assets/images/Key12/12_L_white.png";
import thirteenLBlack from "../assets/images/Key13/13_L_black.png";
import thirteenLLightGrey from "../assets/images/Key13/13_L_lightGrey.png";
import thirteenLDarkGrey from "../assets/images/Key13/13_L_darkGrey.png";
import thirteenLWhite from "../assets/images/Key13/13_L_white.png";
import twelveRBlack from "../assets/images/Key12/12_R_black.png";
import twelveRLightGrey from "../assets/images/Key12/12_R_lightGrey.png";
import twelveRDarkGrey from "../assets/images/Key12/12_R_darkGrey.png";
import twelveRWhite from "../assets/images/Key12/12_R_white.png";
import thirteenRBlack from "../assets/images/Key13/13_R_black.png";
import thirteenRDarkGrey from "../assets/images/Key13/13_R_darkGrey.png";
import thirteenRLightGrey from "../assets/images/Key13/13_R_lightGrey.png";
import thirteenRWhite from "../assets/images/Key13/13_R_white.png";

import redKey from "../assets/images/keyCapButtons/redKey.svg";
import orangeKey from "../assets/images/keyCapButtons/orangeKey.svg";
import yellowKey from "../assets/images/keyCapButtons/yellowKey.svg";
import greenKey from "../assets/images/keyCapButtons/greenKey.svg";
import cyanKey from "../assets/images/keyCapButtons/cyanKey.svg";
import blueKey from "../assets/images/keyCapButtons/blueKey.svg";
import purpleKey from "../assets/images/keyCapButtons/purpleKey.svg";
import pinkKey from "../assets/images/keyCapButtons/pinkKey.svg";
import blackKey from "../assets/images/keyCapButtons/blackKey.svg";
import greyKey from "../assets/images/keyCapButtons/greyKey.svg";
import lightGreyKey from "../assets/images/keyCapButtons/lightGreyKey.svg";
import whiteKey from "../assets/images/keyCapButtons/whiteKey.svg";
import thumbBlackKey from "../assets/images/keyCapButtons/thumbBlackKey.svg";
import thumbGreyKey from "../assets/images/keyCapButtons/thumbGreyKey.svg";
import thumbLightGreyKey from "../assets/images/keyCapButtons/thumbLightGreyKey.svg";
import thumbWhiteKey from "../assets/images/keyCapButtons/thumbWhiteKey.svg";

//TODO:website to do.

//having major issues with key debounce and key chatter? I have investiageted but
//I really dont know what to do or why its happening
// im am going to open up a keyswitch and see whats inside, perhaps a buildup of dust??
// hmm it might be the mech switched them selves. Damien is having the exact same problem
//as I am  but on his cooler master keyboard (and this is the second time this happened with it and 
// his keyboard is less than 1 year old) . . . perhaps mech switches dont last very long at all
// or something.
//I will have to replace damies switch and see if that fixes the debounce issue
//still stalling on this project . . .  i dunno  . . .
//I think i might offer build it yourself kits instead. pivot the product
// yes i will finish the arduino project then finish this project then do a opencv project
//still stalling, still stalling, ive just been really busy this summer and havent coded much. . . 
//maybe look into hotswapable prints and pcbs. that way if there is a switch issue, just swap it

//ok we are going to do hot swappable. i found open source pcb that i need to modify and the hot
//swap sockets. I will now focus on the pcb design for 5x7.
//so we finally have some good news. no idea how hard it will be to make the pcb.s
//ok so the plan now is to:
//1: print out pcb on paper and see if the 6x6 fits
//2: alter the design so the its a 5x7
//3: get made by pcb company
//4: but mil-max hot swap sockets
//i am continuing to learn the software to make pcbs
//watching videos on pcb design
//finished kicad tutorial. now needing to start on redesign
//worked on neck trainer.
//polishing nect trainer content.
//worked on shopify account
//worked on soldering air monitor 
//more soldering if I get to it.


//this is for displaying images in steps 1-5
const ButtonImage = styled.button`
  margin: 3px;
  border-radius: 12px;
  background-color: white;
  border-style: none;
`;

//this is for the black outline when selecting in steps 1-5
const ButtonToggle = styled(ButtonImage)`
  &:hover {
    box-shadow: inset -1px 0 0 darkGrey, inset 0 -1px 0 darkGrey,
      inset 1px 0 0 darkGrey, inset 0 1px 0 darkGrey;
  }
  ${({ active }) =>
    active &&
    `

    box-shadow: inset -1px 0 0 black, inset 0 -1px 0 black,
      inset 1px 0 0 black, inset 0 1px 0 black !important;
  `}
`;

const ButtonToggleDisabled = styled(ButtonImage)`
  cursor: default !important;
`;

const ButtonCheckout = styled.button`
  color: #000000;
  background-color: #e8e8e8;
  border-radius: 12px;
  font-size: 1.3em;
  font-weight: bold;
  opacity: 1;
  padding: 2%;
  margin-top: 5%;
`;

const ButtonCheckoutDisabled = styled.button`
  color: #000000;
  background-color: #e8e8e8;
  border-radius: 12px;
  font-size: 1.3em;
  font-weight: bold;
  opacity: 0.4;
  padding: 2%;
  margin-top: 5%;
  cursor: not-allowed !important;
`;

const ButtonCloseModal = styled.button`
  color: #000000;
  background-color: #ffffff;
  border-radius: 12px;
  font-size: 1.3em;
  opacity: 1;
  padding: 2%;
  margin-top: 5%;
  border-width: thin;
`;

const regexName = new RegExp("^([a-zA-Z?.?,?'?(?)? ?-]){2,75}$");
const regexPlace = new RegExp("^([a-zA-Z0-9?.?,?'?(?)? ?#?&?-]){2,75}$");
const regexPostalCode = new RegExp(
  "^[a-zA-Z][0-9][a-zA-Z][? ?-]?[0-9][a-zA-Z][0-9]$"
);

dotenv.config();

//keyboard function when each colour is selected it can paint the key that is clicked
function Frame() {
  //shipping information
  const [clientEmail, setClientEmail] = useState("");
  const [validClientEmail, setValidClientEmail] = useState(false);

  const [clientFName, setClientFName] = useState("");
  const [validClientFName, setValidClientFName] = useState(false);

  const [clientLName, setClientLName] = useState("");
  const [validClientLName, setValidClientLName] = useState(false);

  const [clientCountry, setClientCountry] = useState("Canada"); //TODO:
  const [validClientCountry, setValidClientCountry] = useState(true);

  const [clientAddress, setClientAddress] = useState("");
  const [validClientAddress, setValidClientAddress] = useState(false);

  const [clientCity, setClientCity] = useState("");
  const [validClientCity, setValidClientCity] = useState(false);

  const [clientProvince, setClientProvince] = useState("");
  const [validClientProvince, setValidClientProvince] = useState(false);

  const [clientPostalCode, setClientPostalCode] = useState("");
  const [validClientPostalCode, setValidClientPostalCode] = useState(false);

  //for form popup
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setValidClientEmail(false);
    setValidClientFName(false);
    setValidClientLName(false);
    setValidClientAddress(false);
    setValidClientCity(false);
    setValidClientProvince(false);
    setValidClientPostalCode(false);
  };

  const handleShow = () => setShow(true);

  const [customizationComplete, setCustomizationComplete] = useState(false);

  const [switchDisable, setSwitchDisable] = useState(false);

  const [frameImage, setFrameImage] = useState(blackFrame);

  const [activeFrame, setActiveFrame] = useState(null);
  const frameTypes = [
    blackThumbnail,
    darkGreyThumbnail,
    lightGreyThumbnail,
    whiteThumbnail,
  ];

  const [activeSwitch, setActiveSwitch] = useState(null);
  const switchTypes = [brownSwitchThumbnail, blueSwitchThumbnail];

  const [labeledKeyImage, setLabeledKeyImage] = useState(null);
  const [labeledKeyDisabled, setLabeledKeyDisabled] = useState(false);

  const [activeLabeledKey, setActiveLabeledKey] = useState(null);
  const labledKeyTypes = [
    keyTextBlackThumbnail,
    keyTextLightGreyThumbnail,
    keyTextWhiteThumbnail,
  ];

  const [unlabeledColorSelector, setUnlabledColorSelector] = useState("");
  const [thumbColorSelector, setThumbColorSelector] = useState("");
  const [selectorDisabled, setSelectorDisabled] = useState(false);

  const thumbColorTypes = [
    thumbBlackKey,
    thumbGreyKey,
    thumbLightGreyKey,
    thumbWhiteKey,
  ];
  const [activeThumbColorSelector, setActiveThumbColorSelector] =
    useState(null);

  const colorTypes = [
    redKey,
    orangeKey,
    yellowKey,
    greenKey,
    cyanKey,
    blueKey,
    purpleKey,
    pinkKey,
    blackKey,
    greyKey,
    lightGreyKey,
    whiteKey,
  ];

  const [activeColorSelector, setActiveColorSelector] = useState(null);

  const [oneL, setOneL] = useState(null);
  const [twoL, setTwoL] = useState(null);
  const [threeL, setThreeL] = useState(null);
  const [fourL, setFourL] = useState(null);
  const [tenL, setTenL] = useState(null);
  const [elevenL, setElevenL] = useState(null);
  const [twelveL, setTwelveL] = useState(null);
  const [thirteenL, setThirteenL] = useState(null);
  const [fourteenL, setFourteenL] = useState(null);
  const [fifteenL, setFifteenL] = useState(null);
  const [sixteenL, setSixteenL] = useState(null);
  const [seventeenL, setSeventeenL] = useState(null);

  const [oneR, setOneR] = useState(null);
  const [twoR, setTwoR] = useState(null);
  const [threeR, setThreeR] = useState(null);
  const [fourR, setFourR] = useState(null);
  const [tenR, setTenR] = useState(null);
  const [elevenR, setElevenR] = useState(null);
  const [twelveR, setTwelveR] = useState(null);
  const [thirteenR, setThirteenR] = useState(null);
  const [fourteenR, setFourteenR] = useState(null);
  const [fifteenR, setFifteenR] = useState(null);
  const [sixteenR, setSixteenR] = useState(null);
  const [seventeenR, setSeventeenR] = useState(null);

  /*--------------------------UNLABELED----------------------*/
  useEffect(() => {
    switch (activeThumbColorSelector) {
      case thumbBlackKey:
        return setThumbColorSelector("black");
      case thumbGreyKey:
        return setThumbColorSelector("darkGrey");
      case thumbLightGreyKey:
        return setThumbColorSelector("lightGrey");
      case thumbWhiteKey:
        return setThumbColorSelector("white");
      default:
        return;
    }
  }, [activeThumbColorSelector]);

  useEffect(() => {
    switch (activeColorSelector) {
      case redKey:
        return setUnlabledColorSelector("red");
      case orangeKey:
        return setUnlabledColorSelector("orange");
      case yellowKey:
        return setUnlabledColorSelector("yellow");
      case greenKey:
        return setUnlabledColorSelector("green");
      case cyanKey:
        return setUnlabledColorSelector("cyan");
      case blueKey:
        return setUnlabledColorSelector("blue");
      case purpleKey:
        return setUnlabledColorSelector("purple");
      case pinkKey:
        return setUnlabledColorSelector("pink");
      case blackKey:
        return setUnlabledColorSelector("black");
      case greyKey:
        return setUnlabledColorSelector("darkGrey");
      case lightGreyKey:
        return setUnlabledColorSelector("lightGrey");
      case whiteKey:
        return setUnlabledColorSelector("white");
      default:
        return;
    }
  }, [activeColorSelector]);

  function changeOneL() {
    switch (unlabeledColorSelector) {
      case "red":
        return setOneL(oneLRed);
      case "orange":
        return setOneL(oneLOrange);
      case "yellow":
        return setOneL(oneLYellow);
      case "green":
        return setOneL(oneLGreen);
      case "cyan":
        return setOneL(oneLCyan);
      case "blue":
        return setOneL(oneLBlue);
      case "purple":
        return setOneL(oneLPurple);
      case "pink":
        return setOneL(oneLPink);
      case "black":
        return setOneL(oneLBlack);
      case "darkGrey":
        return setOneL(oneLDarkGrey);
      case "lightGrey":
        return setOneL(oneLLightGrey);
      case "white":
        return setOneL(oneLWhite);
      default:
        return;
    }
  }

  function changeTwoL() {
    switch (unlabeledColorSelector) {
      case "red":
        return setTwoL(twoLRed);
      case "orange":
        return setTwoL(twoLOrange);
      case "yellow":
        return setTwoL(twoLYellow);
      case "green":
        return setTwoL(twoLGreen);
      case "cyan":
        return setTwoL(twoLCyan);
      case "blue":
        return setTwoL(twoLBlue);
      case "purple":
        return setTwoL(twoLPurple);
      case "pink":
        return setTwoL(twoLPink);
      case "black":
        return setTwoL(twoLBlack);
      case "darkGrey":
        return setTwoL(twoLDarkGrey);
      case "lightGrey":
        return setTwoL(twoLLightGrey);
      case "white":
        return setTwoL(twoLWhite);
      default:
        return;
    }
  }

  function changeThreeL() {
    switch (unlabeledColorSelector) {
      case "red":
        return setThreeL(threeLRed);
      case "orange":
        return setThreeL(threeLOrange);
      case "yellow":
        return setThreeL(threeLYellow);
      case "green":
        return setThreeL(threeLGreen);
      case "cyan":
        return setThreeL(threeLCyan);
      case "blue":
        return setThreeL(threeLBlue);
      case "purple":
        return setThreeL(threeLPurple);
      case "pink":
        return setThreeL(threeLPink);
      case "black":
        return setThreeL(threeLBlack);
      case "darkGrey":
        return setThreeL(threeLDarkGrey);
      case "lightGrey":
        return setThreeL(threeLLightGrey);
      case "white":
        return setThreeL(threeLWhite);
      default:
        return;
    }
  }

  function changeFourL() {
    switch (unlabeledColorSelector) {
      case "red":
        return setFourL(fourLRed);
      case "orange":
        return setFourL(fourLOrange);
      case "yellow":
        return setFourL(fourLYellow);
      case "green":
        return setFourL(fourLGreen);
      case "cyan":
        return setFourL(fourLCyan);
      case "blue":
        return setFourL(fourLBlue);
      case "purple":
        return setFourL(fourLPurple);
      case "pink":
        return setFourL(fourLPink);
      case "black":
        return setFourL(fourLBlack);
      case "darkGrey":
        return setFourL(fourLDarkGrey);
      case "lightGrey":
        return setFourL(fourLLightGrey);
      case "white":
        return setFourL(fourLWhite);
      default:
        return;
    }
  }

  function changeTenL() {
    switch (unlabeledColorSelector) {
      case "red":
        return setTenL(tenLRed);
      case "orange":
        return setTenL(tenLOrange);
      case "yellow":
        return setTenL(tenLYellow);
      case "green":
        return setTenL(tenLGreen);
      case "cyan":
        return setTenL(tenLCyan);
      case "blue":
        return setTenL(tenLBlue);
      case "purple":
        return setTenL(tenLPurple);
      case "pink":
        return setTenL(tenLPink);
      case "black":
        return setTenL(tenLBlack);
      case "darkGrey":
        return setTenL(tenLDarkGrey);
      case "lightGrey":
        return setTenL(tenLLightGrey);
      case "white":
        return setTenL(tenLWhite);
      default:
        return;
    }
  }

  function changeElevenL() {
    switch (unlabeledColorSelector) {
      case "red":
        return setElevenL(elevenLRed);
      case "orange":
        return setElevenL(elevenLOrange);
      case "yellow":
        return setElevenL(elevenLYellow);
      case "green":
        return setElevenL(elevenLGreen);
      case "cyan":
        return setElevenL(elevenLCyan);
      case "blue":
        return setElevenL(elevenLBlue);
      case "purple":
        return setElevenL(elevenLPurple);
      case "pink":
        return setElevenL(elevenLPink);
      case "black":
        return setElevenL(elevenLBlack);
      case "darkGrey":
        return setElevenL(elevenLDarkGrey);
      case "lightGrey":
        return setElevenL(elevenLLightGrey);
      case "white":
        return setElevenL(elevenLWhite);
      default:
        return;
    }
  }

  function changeTwelveL() {
    switch (thumbColorSelector) {
      case "black":
        return setTwelveL(twelveLBlack);
      case "darkGrey":
        return setTwelveL(twelveLDarkGrey);
      case "lightGrey":
        return setTwelveL(twelveLLightGrey);
      case "white":
        return setTwelveL(twelveLWhite);
      default:
        return;
    }
  }

  function changeThirteenL() {
    switch (thumbColorSelector) {
      case "black":
        return setThirteenL(thirteenLBlack);
      case "darkGrey":
        return setThirteenL(thirteenLDarkGrey);
      case "lightGrey":
        return setThirteenL(thirteenLLightGrey);
      case "white":
        return setThirteenL(thirteenLWhite);
      default:
        return;
    }
  }

  function changeFourteenL() {
    switch (unlabeledColorSelector) {
      case "red":
        return setFourteenL(fourteenLRed);
      case "orange":
        return setFourteenL(fourteenLOrange);
      case "yellow":
        return setFourteenL(fourteenLYellow);
      case "green":
        return setFourteenL(fourteenLGreen);
      case "cyan":
        return setFourteenL(fourteenLCyan);
      case "blue":
        return setFourteenL(fourteenLBlue);
      case "purple":
        return setFourteenL(fourteenLPurple);
      case "pink":
        return setFourteenL(fourteenLPink);
      case "black":
        return setFourteenL(fourteenLBlack);
      case "darkGrey":
        return setFourteenL(fourteenLDarkGrey);
      case "lightGrey":
        return setFourteenL(fourteenLLightGrey);
      case "white":
        return setFourteenL(fourteenLWhite);
      default:
        return;
    }
  }

  function changeFifteenL() {
    switch (unlabeledColorSelector) {
      case "red":
        return setFifteenL(fifteenLRed);
      case "orange":
        return setFifteenL(fifteenLOrange);
      case "yellow":
        return setFifteenL(fifteenLYellow);
      case "green":
        return setFifteenL(fifteenLGreen);
      case "cyan":
        return setFifteenL(fifteenLCyan);
      case "blue":
        return setFifteenL(fifteenLBlue);
      case "purple":
        return setFifteenL(fifteenLPurple);
      case "pink":
        return setFifteenL(fifteenLPink);
      case "black":
        return setFifteenL(fifteenLBlack);
      case "darkGrey":
        return setFifteenL(fifteenLDarkGrey);
      case "lightGrey":
        return setFifteenL(fifteenLLightGrey);
      case "white":
        return setFifteenL(fifteenLWhite);
      default:
        return;
    }
  }

  function changeSixteenL() {
    switch (unlabeledColorSelector) {
      case "red":
        return setSixteenL(sixteenLRed);
      case "orange":
        return setSixteenL(sixteenLOrange);
      case "yellow":
        return setSixteenL(sixteenLYellow);
      case "green":
        return setSixteenL(sixteenLGreen);
      case "cyan":
        return setSixteenL(sixteenLCyan);
      case "blue":
        return setSixteenL(sixteenLBlue);
      case "purple":
        return setSixteenL(sixteenLPurple);
      case "pink":
        return setSixteenL(sixteenLPink);
      case "black":
        return setSixteenL(sixteenLBlack);
      case "darkGrey":
        return setSixteenL(sixteenLDarkGrey);
      case "lightGrey":
        return setSixteenL(sixteenLLightGrey);
      case "white":
        return setSixteenL(sixteenLWhite);
      default:
        return;
    }
  }

  function changeSeveteenL() {
    switch (unlabeledColorSelector) {
      case "red":
        return setSeventeenL(seventeenLRed);
      case "orange":
        return setSeventeenL(seventeenLOrange);
      case "yellow":
        return setSeventeenL(seventeenLYellow);
      case "green":
        return setSeventeenL(seventeenLGreen);
      case "cyan":
        return setSeventeenL(seventeenLCyan);
      case "blue":
        return setSeventeenL(seventeenLBlue);
      case "purple":
        return setSeventeenL(seventeenLPurple);
      case "pink":
        return setSeventeenL(seventeenLPink);
      case "black":
        return setSeventeenL(seventeenLBlack);
      case "darkGrey":
        return setSeventeenL(seventeenLDarkGrey);
      case "lightGrey":
        return setSeventeenL(seventeenLLightGrey);
      case "white":
        return setSeventeenL(seventeenLWhite);
      default:
        return;
    }
  }

  function changeOneR() {
    switch (unlabeledColorSelector) {
      case "red":
        return setOneR(oneRRed);
      case "orange":
        return setOneR(oneROrange);
      case "yellow":
        return setOneR(oneRYellow);
      case "green":
        return setOneR(oneRGreen);
      case "cyan":
        return setOneR(oneRCyan);
      case "blue":
        return setOneR(oneRBlue);
      case "purple":
        return setOneR(oneRPurple);
      case "pink":
        return setOneR(oneRPink);
      case "black":
        return setOneR(oneRBlack);
      case "darkGrey":
        return setOneR(oneRDarkGrey);
      case "lightGrey":
        return setOneR(oneRLightGrey);
      case "white":
        return setOneR(oneRWhite);
      default:
        return;
    }
  }

  function changeTwoR() {
    switch (unlabeledColorSelector) {
      case "red":
        return setTwoR(twoRRed);
      case "orange":
        return setTwoR(twoROrange);
      case "yellow":
        return setTwoR(twoRYellow);
      case "green":
        return setTwoR(twoRGreen);
      case "cyan":
        return setTwoR(twoRCyan);
      case "blue":
        return setTwoR(twoRBlue);
      case "purple":
        return setTwoR(twoRPurple);
      case "pink":
        return setTwoR(twoRPink);
      case "black":
        return setTwoR(twoRBlack);
      case "darkGrey":
        return setTwoR(twoRDarkGrey);
      case "lightGrey":
        return setTwoR(twoRLightGrey);
      case "white":
        return setTwoR(twoRWhite);
      default:
        return;
    }
  }

  function changeThreeR() {
    switch (unlabeledColorSelector) {
      case "red":
        return setThreeR(threeRRed);
      case "orange":
        return setThreeR(threeROrange);
      case "yellow":
        return setThreeR(threeRYellow);
      case "green":
        return setThreeR(threeRGreen);
      case "cyan":
        return setThreeR(threeRCyan);
      case "blue":
        return setThreeR(threeRBlue);
      case "purple":
        return setThreeR(threeRPurple);
      case "pink":
        return setThreeR(threeRPink);
      case "black":
        return setThreeR(threeRBlack);
      case "darkGrey":
        return setThreeR(threeRDarkGrey);
      case "lightGrey":
        return setThreeR(threeRLightGrey);
      case "white":
        return setThreeR(threeRWhite);
      default:
        return;
    }
  }

  function changeFourR() {
    switch (unlabeledColorSelector) {
      case "red":
        return setFourR(fourRRed);
      case "orange":
        return setFourR(fourROrange);
      case "yellow":
        return setFourR(fourRYellow);
      case "green":
        return setFourR(fourRGreen);
      case "cyan":
        return setFourR(fourRCyan);
      case "blue":
        return setFourR(fourRBlue);
      case "purple":
        return setFourR(fourRPurple);
      case "pink":
        return setFourR(fourRPink);
      case "black":
        return setFourR(fourRBlack);
      case "darkGrey":
        return setFourR(fourRDarkGrey);
      case "lightGrey":
        return setFourR(fourRLightGrey);
      case "white":
        return setFourR(fourRWhite);
      default:
        return;
    }
  }

  function changeTenR() {
    switch (unlabeledColorSelector) {
      case "red":
        return setTenR(tenRRed);
      case "orange":
        return setTenR(tenROrange);
      case "yellow":
        return setTenR(tenRYellow);
      case "green":
        return setTenR(tenRGreen);
      case "cyan":
        return setTenR(tenRCyan);
      case "blue":
        return setTenR(tenRBlue);
      case "purple":
        return setTenR(tenRPurple);
      case "pink":
        return setTenR(tenRPink);
      case "black":
        return setTenR(tenRBlack);
      case "darkGrey":
        return setTenR(tenRDarkGrey);
      case "lightGrey":
        return setTenR(tenRLightGrey);
      case "white":
        return setTenR(tenRWhite);
      default:
        return;
    }
  }

  function changeElevenR() {
    switch (unlabeledColorSelector) {
      case "red":
        return setElevenR(elevenRRed);
      case "orange":
        return setElevenR(elevenROrange);
      case "yellow":
        return setElevenR(elevenRYellow);
      case "green":
        return setElevenR(elevenRGreen);
      case "cyan":
        return setElevenR(elevenRCyan);
      case "blue":
        return setElevenR(elevenRBlue);
      case "purple":
        return setElevenR(elevenRPurple);
      case "pink":
        return setElevenR(elevenRPink);
      case "black":
        return setElevenR(elevenRBlack);
      case "darkGrey":
        return setElevenR(elevenRDarkGrey);
      case "lightGrey":
        return setElevenR(elevenRLightGrey);
      case "white":
        return setElevenR(elevenRWhite);
      default:
        return;
    }
  }

  function changeTwelveR() {
    switch (thumbColorSelector) {
      case "black":
        return setTwelveR(twelveRBlack);
      case "darkGrey":
        return setTwelveR(twelveRDarkGrey);
      case "lightGrey":
        return setTwelveR(twelveRLightGrey);
      case "white":
        return setTwelveR(twelveRWhite);
      default:
        return;
    }
  }

  function changeThirteenR() {
    switch (thumbColorSelector) {
      case "black":
        return setThirteenR(thirteenRBlack);
      case "darkGrey":
        return setThirteenR(thirteenRDarkGrey);
      case "lightGrey":
        return setThirteenR(thirteenRLightGrey);
      case "white":
        return setThirteenR(thirteenRWhite);
      default:
        return;
    }
  }

  function changeFourteenR() {
    switch (unlabeledColorSelector) {
      case "red":
        return setFourteenR(fourteenRRed);
      case "orange":
        return setFourteenR(fourteenROrange);
      case "yellow":
        return setFourteenR(fourteenRYellow);
      case "green":
        return setFourteenR(fourteenRGreen);
      case "cyan":
        return setFourteenR(fourteenRCyan);
      case "blue":
        return setFourteenR(fourteenRBlue);
      case "purple":
        return setFourteenR(fourteenRPurple);
      case "pink":
        return setFourteenR(fourteenRPink);
      case "black":
        return setFourteenR(fourteenRBlack);
      case "darkGrey":
        return setFourteenR(fourteenRDarkGrey);
      case "lightGrey":
        return setFourteenR(fourteenRLightGrey);
      case "white":
        return setFourteenR(fourteenRWhite);
      default:
        return;
    }
  }

  function changeFifteenR() {
    switch (unlabeledColorSelector) {
      case "red":
        return setFifteenR(fifteenRRed);
      case "orange":
        return setFifteenR(fifteenROrange);
      case "yellow":
        return setFifteenR(fifteenRYellow);
      case "green":
        return setFifteenR(fifteenRGreen);
      case "cyan":
        return setFifteenR(fifteenRCyan);
      case "blue":
        return setFifteenR(fifteenRBlue);
      case "purple":
        return setFifteenR(fifteenRPurple);
      case "pink":
        return setFifteenR(fifteenRPink);
      case "black":
        return setFifteenR(fifteenRBlack);
      case "darkGrey":
        return setFifteenR(fifteenRDarkGrey);
      case "lightGrey":
        return setFifteenR(fifteenRLightGrey);
      case "white":
        return setFifteenR(fifteenRWhite);
      default:
        return;
    }
  }

  function changeSixteenR() {
    switch (unlabeledColorSelector) {
      case "red":
        return setSixteenR(sixteenRRed);
      case "orange":
        return setSixteenR(sixteenROrange);
      case "yellow":
        return setSixteenR(sixteenRYellow);
      case "green":
        return setSixteenR(sixteenRGreen);
      case "cyan":
        return setSixteenR(sixteenRCyan);
      case "blue":
        return setSixteenR(sixteenRBlue);
      case "purple":
        return setSixteenR(sixteenRPurple);
      case "pink":
        return setSixteenR(sixteenRPink);
      case "black":
        return setSixteenR(sixteenRBlack);
      case "darkGrey":
        return setSixteenR(sixteenRDarkGrey);
      case "lightGrey":
        return setSixteenR(sixteenRLightGrey);
      case "white":
        return setSixteenR(sixteenRWhite);
      default:
        return;
    }
  }

  function changeSeveteenR() {
    switch (unlabeledColorSelector) {
      case "red":
        return setSeventeenR(seventeenRRed);
      case "orange":
        return setSeventeenR(seventeenROrange);
      case "yellow":
        return setSeventeenR(seventeenRYellow);
      case "green":
        return setSeventeenR(seventeenRGreen);
      case "cyan":
        return setSeventeenR(seventeenRCyan);
      case "blue":
        return setSeventeenR(seventeenRBlue);
      case "purple":
        return setSeventeenR(seventeenRPurple);
      case "pink":
        return setSeventeenR(seventeenRPink);
      case "black":
        return setSeventeenR(seventeenRBlack);
      case "darkGrey":
        return setSeventeenR(seventeenRDarkGrey);
      case "lightGrey":
        return setSeventeenR(seventeenRLightGrey);
      case "white":
        return setSeventeenR(seventeenRWhite);
      default:
        return;
    }
  }

  /*--------------------------LABELED----------------------*/
  //checks which button was clicked and assigns appropriate render image
  useEffect(() => {
    switch (activeLabeledKey) {
      case keyTextBlackThumbnail:
        setSelectorDisabled(true);
        return setLabeledKeyImage(keyTextBlackPic);
      case keyTextLightGreyThumbnail:
        setSelectorDisabled(true);
        return setLabeledKeyImage(keyTextLightGreyPic);
      case keyTextWhiteThumbnail:
        setSelectorDisabled(true);
        return setLabeledKeyImage(keyTextWhitePic);
      default:
        return;
    }
  }, [activeLabeledKey]);

  /*--------------------------FRAME----------------------*/
  //keeps track of which buttons are pressed and assigns render image appropriately
  useEffect(() => {
    if (activeSwitch !== null && activeSwitch !== "") {
      // blue or brown switches
      setLabeledKeyDisabled(true);
      if (activeFrame === blackThumbnail) {
        if (activeSwitch === blueSwitchThumbnail) {
          setFrameImage(blackFrameBlueSwitch);
          return;
        } else {
          setFrameImage(blackFrameBrownSwitch);
          return;
        }
      }
      if (activeFrame === darkGreyThumbnail) {
        if (activeSwitch === blueSwitchThumbnail) {
          setFrameImage(darkGreyFrameBlueSwitch);
          return;
        } else {
          setFrameImage(darkGreyFrameBrownSwitch);
          return;
        }
      }
      if (activeFrame === lightGreyThumbnail) {
        if (activeSwitch === blueSwitchThumbnail) {
          setFrameImage(lightGreyFrameBlueSwitch);
          return;
        } else {
          setFrameImage(lightGreyFrameBrownSwitch);
          return;
        }
      }
      if (activeFrame === whiteThumbnail) {
        if (activeSwitch === blueSwitchThumbnail) {
          setFrameImage(whiteFrameBlueSwitch);
          return;
        } else {
          setFrameImage(whiteFrameBrownSwitch);
          return;
        }
      }
      //if no switchs clicked
    }

    if (activeFrame === blackThumbnail) {
      setFrameImage(blackFrame);
      setSwitchDisable(true);
    } else if (activeFrame === darkGreyThumbnail) {
      setFrameImage(darkGreyFrame);
      setSwitchDisable(true);
    } else if (activeFrame === lightGreyThumbnail) {
      setFrameImage(lightGreyFrame);
      setSwitchDisable(true);
    } else if (activeFrame === whiteThumbnail) {
      setFrameImage(whiteFrame);
      setSwitchDisable(true);
    }
  }, [activeFrame, activeSwitch]);

  useEffect(() => {
    if (
      oneL != null &&
      twoL != null &&
      threeL != null &&
      fourL != null &&
      tenL != null &&
      elevenL != null &&
      twelveL != null &&
      thirteenL != null &&
      fourteenL != null &&
      fifteenL != null &&
      sixteenL != null &&
      seventeenL != null &&
      oneR != null &&
      twoR != null &&
      threeR != null &&
      fourR != null &&
      tenR != null &&
      elevenR != null &&
      twelveR != null &&
      thirteenR != null &&
      fourteenR != null &&
      fifteenR != null &&
      sixteenR != null &&
      seventeenR != null
    ) {
      setCustomizationComplete(true);
    }
  }, [
    oneL,
    twoL,
    threeL,
    fourL,
    tenL,
    elevenL,
    twelveL,
    thirteenL,
    fourteenL,
    fifteenL,
    sixteenL,
    seventeenL,
    oneR,
    twoR,
    threeR,
    fourR,
    tenR,
    elevenR,
    twelveR,
    thirteenR,
    fourteenR,
    fifteenR,
    sixteenR,
    seventeenR,
  ]);

  useEffect(() => {
    async function fetchEmailData() {
      let emailData = {
        email: clientEmail,
      };
      const checkEmail = await emailSchema.isValid(emailData);
      if (checkEmail && clientEmail.length < 50) {
        setValidClientEmail(true);
      } else {
        setValidClientEmail(false);
      }
    }
    fetchEmailData().catch(console.error);
    //asnyc useEffect
  }, [clientEmail]);

  useEffect(() => {
    async function fetchfNameData() {
      var regexResult = regexName.test(clientFName);
      if (regexResult && clientFName.length < 40) {
        setValidClientFName(true);
      } else {
        setValidClientFName(false);
      }
    }
    fetchfNameData().catch(console.error);
  }, [clientFName]);

  useEffect(() => {
    async function fetchLNameData() {
      var regexResult = regexName.test(clientLName);
      if (regexResult & (clientLName.length < 40)) {
        setValidClientLName(true);
      } else {
        setValidClientLName(false);
      }
    }
    fetchLNameData().catch(console.error);
  }, [clientLName]);

  useEffect(() => {
    async function fetchClientAddressData() {
      var regexResult = regexPlace.test(clientAddress);
      if (regexResult && clientAddress.length < 40) {
        setValidClientAddress(true);
      } else {
        setValidClientAddress(false);
      }
    }
    fetchClientAddressData().catch(console.error);
  }, [clientAddress]);

  useEffect(() => {
    async function fetchClientCityData() {
      var regexResult = regexPlace.test(clientCity);
      if (regexResult && clientCity.length < 40) {
        setValidClientCity(true);
      } else {
        setValidClientCity(false);
      }
    }
    fetchClientCityData().catch(console.error);
  }, [clientCity]);

  useEffect(() => {
    async function fetchClientProvinceData() {
      var validProvince = false;
      switch (clientProvince) {
        case "ab":
        case "bc":
        case "mb":
        case "nb":
        case "nl":
        case "nt":
        case "ns":
        case "nu":
        case "on":
        case "pe":
        case "qc":
        case "sk":
        case "yt":
          validProvince = true;
          break;
        default:
          validProvince = false;
          break;
      }
      if (validProvince) {
        setValidClientProvince(true);
      } else {
        setValidClientProvince(false);
      }
    }
    fetchClientProvinceData().catch(console.error);
  }, [clientProvince]);

  useEffect(() => {
    async function fetchClientPostalData() {
      var regexResult = regexPostalCode.test(clientPostalCode);
      if (regexResult && clientPostalCode.length < 8) {
        setValidClientPostalCode(true);
      } else {
        setValidClientPostalCode(false);
      }
    }
    fetchClientPostalData().catch(console.error);
  }, [clientPostalCode]);

  const openStripe = () => {
    var url = "";
    var urlArray = process.env.REACT_APP_URL.split(",");
    if (urlArray.length === 1 && urlArray[0] !== undefined) {
      url = urlArray[0];
    } else if (urlArray.length === 2 && urlArray[1] !== undefined) {
      url = urlArray[1];
    }

    fetch(`${url}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        items: [
          {
            id: 1,
            clientEmail: clientEmail,
            clientFName: clientFName,
            clientLName: clientLName,
            clientCountry: clientCountry,
            clientAddress: clientAddress,
            clientCity: clientCity,
            clientProvince: clientProvince,
            clientPostalCode: clientPostalCode,
            quantity: 1,
            frameAndSwitch: frameImage,
            labeledKeyColor: labeledKeyImage,
            oneL: oneL,
            twoL: twoL,
            threeL: threeL,
            fourL: fourL,
            tenL: tenL,
            elevenL: elevenL,
            twelveL: twelveL,
            thirteenL: thirteenL,
            fourteenL: fourteenL,
            fifteenL: fifteenL,
            sixteenL: sixteenL,
            seventeenL: seventeenL,
            oneR: oneR,
            twoR: twoR,
            threeR: threeR,
            fourR: fourR,
            tenR: tenR,
            elevenR: elevenR,
            twelveR: twelveR,
            thirteenR: thirteenR,
            fourteenR: fourteenR,
            fifteenR: fifteenR,
            sixteenR: sixteenR,
            seventeenR: seventeenR,
          },
        ],
      }),
      //get response back from server and send user to url. if fail
      //catch error
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ url }) => {
        //console.log(url);
        window.location = url;
      })
      .catch((e) => {
        //console.error(e.error);
      });
  };

  return (
    <>
      <div className="Main">
        <div className="renderHeader">Design Your Own Keyboard</div>
        <div className="grid-container">
          <div className="grid-item">
            <div className="box">
              <img src={frameImage} alt="" className="static" />

              <img src={oneL} alt="" className="layer" />

              <img src={oneR} alt="" className="layer" />

              <img src={twoL} alt="" className="layer" />

              <img src={twoR} alt="" className="layer" />

              <img src={threeL} alt="" className="layer" />

              <img src={threeR} alt="" className="layer" />

              <img src={fourL} alt="" className="layer" />

              <img src={fourR} alt="" className="layer" />

              <img src={labeledKeyImage} alt="" className="layer" />

              <img src={tenL} alt="" className="layer" />

              <img src={tenR} alt="" className="layer" />

              <img src={elevenL} alt="" className="layer" />

              <img src={elevenR} alt="" className="layer" />

              <img src={twelveL} alt="" className="layer" />

              <img src={twelveR} alt="" className="layer" />

              <img src={thirteenL} alt="" className="layer" />

              <img src={thirteenR} alt="" className="layer" />

              <img src={fourteenL} alt="" className="layer" />

              <img src={fourteenR} alt="" className="layer" />

              <img src={fifteenL} alt="" className="layer" />

              <img src={fifteenR} alt="" className="layer" />

              <img src={sixteenL} alt="" className="layer" />

              <img src={sixteenR} alt="" className="layer" />

              <img src={seventeenL} alt="" className="layer" />

              <img src={seventeenR} alt="" className="layer" />

              {/*This is to display the helper arrows for selecting a color of the large thumb keys*/}
              {thumbColorSelector !== "" &&
              !twelveL &&
              !thirteenL &&
              !twelveR &&
              !thirteenR ? (
                <img src={largeThumbArrows} alt="" className="layer" />
              ) : (
                <div></div>
              )}

              {/*This is to display the helper arrows for selecting a color of the unlabeled keys*/}
              {unlabeledColorSelector !== "" &&
              !oneL &&
              !twoL &&
              !threeL &&
              !fourL &&
              !tenL &&
              !elevenL &&
              !fourteenL &&
              !fifteenL &&
              !sixteenL &&
              !seventeenL &&
              !oneR &&
              !twoR &&
              !threeR &&
              !fourR &&
              !tenR &&
              !elevenR &&
              !fourteenR &&
              !fifteenR &&
              !sixteenR &&
              !seventeenR ? (
                <img src={unlabeledArrows} alt="" className="layer" />
              ) : (
                <div></div>
              )}

              {/*These are for the invible buttons on top of the render image*/}
              <button className="oneL" onClick={changeOneL}>
                1l
              </button>
              <button className="twoL" onClick={changeTwoL}>
                2l
              </button>
              <button className="threeL" onClick={changeThreeL}>
                3l
              </button>
              <button className="fourL" onClick={changeFourL}>
                4l
              </button>
              <button className="tenL" onClick={changeTenL}>
                10l
              </button>
              <button className="elevenL" onClick={changeElevenL}>
                11l
              </button>
              <button className="twelveL" onClick={changeTwelveL}>
                12l
              </button>
              <button className="thirteenL" onClick={changeThirteenL}>
                13l
              </button>
              <button className="fourteenL" onClick={changeFourteenL}>
                14l
              </button>
              <button className="fifteenL" onClick={changeFifteenL}>
                15l
              </button>
              <button className="sixteenL" onClick={changeSixteenL}>
                16l
              </button>
              <button className="seventeenL" onClick={changeSeveteenL}>
                17l
              </button>

              <button className="oneR" onClick={changeOneR}>
                lr
              </button>
              <button className="twoR" onClick={changeTwoR}>
                2r
              </button>
              <button className="threeR" onClick={changeThreeR}>
                3r
              </button>
              <button className="fourR" onClick={changeFourR}>
                4r
              </button>
              <button className="tenR" onClick={changeTenR}>
                10r
              </button>
              <button className="elevenR" onClick={changeElevenR}>
                11r
              </button>
              <button className="twelveR" onClick={changeTwelveR}>
                12r
              </button>
              <button className="thirteenR" onClick={changeThirteenR}>
                13r
              </button>
              <button className="fourteenR" onClick={changeFourteenR}>
                14r
              </button>
              <button className="fifteenR" onClick={changeFifteenR}>
                15r
              </button>
              <button className="sixteenR" onClick={changeSixteenR}>
                16r
              </button>
              <button className="seventeenR" onClick={changeSeveteenR}>
                17r
              </button>
            </div>
          </div>

          {/*selection panel*/}
          <div className="grid-item">
            <div className="renderSubHeader">1. Select a Frame:</div>
            {frameTypes.map((frameType) => (
              <ButtonToggle
                key={frameType}
                active={activeFrame === frameType}
                onClick={() => setActiveFrame(frameType)}
              >
                {<img src={frameType} alt=""></img>}
              </ButtonToggle>
            ))}
            {switchDisable ? (
              <div>
                <div className="renderSubHeader">2. Choose Switch Type:</div>
                {switchTypes.map((switchType) => (
                  <ButtonToggle
                    key={switchType}
                    active={activeSwitch === switchType}
                    onClick={() => setActiveSwitch(switchType)}
                  >
                    {<img src={switchType} alt=""></img>}
                  </ButtonToggle>
                ))}
              </div>
            ) : (
              <div>
                <div className="renderSubHeaderDisabled">
                  2. Choose Switch Type:
                </div>
                {switchTypes.map((switchType) => (
                  <ButtonToggleDisabled key={switchType.toString()}>
                    {
                      <img
                        className="thumbnailDisabled"
                        src={switchType}
                        alt=""
                      ></img>
                    }
                  </ButtonToggleDisabled>
                ))}
              </div>
            )}
            {labeledKeyDisabled ? (
              <div>
                <div className="renderSubHeader">3. Choose Labeled Keys:</div>
                {labledKeyTypes.map((labledKeyType) => (
                  <ButtonToggle
                    key={labledKeyType}
                    active={activeLabeledKey === labledKeyType}
                    onClick={() => setActiveLabeledKey(labledKeyType)}
                  >
                    {<img src={labledKeyType} alt=""></img>}
                  </ButtonToggle>
                ))}
              </div>
            ) : (
              <div>
                <div className="renderSubHeaderDisabled">
                  3. Choose Labeled Keys:
                </div>
                {labledKeyTypes.map((labledKeyType) => (
                  <ButtonToggleDisabled key={labledKeyType.toString()}>
                    {
                      <img
                        className="thumbnailDisabled"
                        src={labledKeyType}
                        alt=""
                      ></img>
                    }
                  </ButtonToggleDisabled>
                  /*TODO: i would like the arrow to flash for a half second each time the user
                clicks a color selector */
                ))}
              </div>
            )}
            {selectorDisabled ? (
              <div>
                <div className="renderSubHeader">
                  4. Select Large Thumb Keycap Color:
                </div>
                <div className="details">
                  Select a color, then click any empty spot for large thumb key
                </div>
                <div>
                  {thumbColorTypes.map((thumbColorType) => (
                    <ButtonToggle
                      key={thumbColorType}
                      active={activeThumbColorSelector === thumbColorType}
                      onClick={() =>
                        setActiveThumbColorSelector(thumbColorType)
                      }
                    >
                      {<img src={thumbColorType} alt=""></img>}
                    </ButtonToggle>
                  ))}
                </div>

                <div className="renderSubHeader">
                  5. Select Unlabeled Keycap Color:
                </div>
                <div className="details">
                  Select a color, then click any empty spot on the keyboard
                </div>
                {colorTypes.map((colorType) => (
                  <ButtonToggle
                    key={colorType}
                    active={activeColorSelector === colorType}
                    onClick={() => setActiveColorSelector(colorType)}
                  >
                    {<img src={colorType} alt=""></img>}
                  </ButtonToggle>
                ))}
              </div>
            ) : (
              <div>
                <div className="renderSubHeaderDisabled">
                  4. Select Large Thumb Keycap Color:
                </div>
                <div className="thumbnailDisabled">
                  Select a color, then click any empty spot for large thumb key
                </div>
                <div>
                  {thumbColorTypes.map((thumbColorType) => (
                    <ButtonToggleDisabled
                      key={thumbColorType}
                      active={activeThumbColorSelector === thumbColorType}
                    >
                      {
                        <img
                          className="thumbnailDisabled"
                          src={thumbColorType}
                          alt=""
                        ></img>
                      }
                    </ButtonToggleDisabled>
                  ))}
                </div>

                <div className="renderSubHeaderDisabled">
                  5. Select Unlabeled Keycap Color:
                </div>
                <div className="thumbnailDisabled">
                  Select a color, then click any empty spot on the keyboard
                </div>
                {colorTypes.map((colorType) => (
                  <ButtonToggleDisabled key={colorType.toString()}>
                    {
                      <img
                        className="thumbnailDisabled"
                        src={colorType}
                        alt=""
                      ></img>
                    }
                  </ButtonToggleDisabled>
                ))}
              </div>
            )}

            <div className="renderSubHeader">$399 CAD + Free Shipping</div>
            <div className="details">
              Includes: Braided usb and aux cables, extra homing keys, keycap
              puller
            </div>
            {/*remove when ready*/}
            <div className="weak">
              Checkout disabled, Site under construction, check back soon, thx
            </div>

            <div>
              {customizationComplete ? (
                //TODO: a progress bar(in react-boodstrap) would be a super cool feature
                //TODO: need to add a hover to tell user they need to select everythin befor
                <div>
                  <button
                    className="proceedToCheckoutEnabled"
                    onClick={() => handleShow()}
                  >
                    Proceed to Checkout
                  </button>

                  {/*
                  //enable to disable checkout
                   <button className="proceedToCheckoutDisabled">
                    Proceed to Checkout
                  </button> */}

                  <span className="logoSpan">
                    <img
                      className="stripLogo"
                      src={poweredByStripe}
                      alt="powered by Stripe"
                    ></img>
                  </span>
                </div>
              ) : (
                <div>
                  <button className="proceedToCheckoutDisabled">
                    Proceed to Checkout
                  </button>
                  <span className="logoSpan">
                    <img
                      className="stripLogoDisabled"
                      src={poweredByStripe}
                      alt="powered by Stripe"
                    ></img>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        <>
          {/* I know this is getting super messy and should be its own component*/}
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Shipping and Contact Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="SCI.email">
                  <Form.Label className="formLabel">
                    Email address
                    {validClientEmail ? (
                      <div className="child" style={{ color: "green" }}>
                        ✓
                      </div>
                    ) : (
                      <div className="child" style={{ color: "red" }}>
                        *
                      </div>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    autoFocus
                    onChange={(e) => setClientEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="SCI.fName">
                  <Form.Label className="formLabel">
                    First Name
                    {validClientFName ? (
                      <div className="child" style={{ color: "green" }}>
                        ✓
                      </div>
                    ) : (
                      <div className="child" style={{ color: "red" }}>
                        *
                      </div>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    onChange={(e) => setClientFName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="SCI.lName">
                  <Form.Label>
                    Last Name
                    {validClientLName ? (
                      <div className="child" style={{ color: "green" }}>
                        ✓
                      </div>
                    ) : (
                      <div className="child" style={{ color: "red" }}>
                        *
                      </div>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    onChange={(e) => setClientLName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="SCI.Country">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Canada"
                    defaultValue="Canada"
                    disabled
                  />
                </Form.Group>
                <span className="weak">
                  Sorry, at this time we are only shipping within Canada
                </span>

                <Form.Group className="mb-3" controlId="SCI.address">
                  <Form.Label>
                    Address
                    {validClientAddress ? (
                      <div className="child" style={{ color: "green" }}>
                        ✓
                      </div>
                    ) : (
                      <div className="child" style={{ color: "red" }}>
                        *
                      </div>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address Line 1"
                    onChange={(e) => setClientAddress(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="SCI.city">
                  <Form.Label>
                    City
                    {validClientCity ? (
                      <div className="child" style={{ color: "green" }}>
                        ✓
                      </div>
                    ) : (
                      <div className="child" style={{ color: "red" }}>
                        *
                      </div>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City Name"
                    onChange={(e) => setClientCity(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="SCI.province">
                  <Form.Label>
                    Province
                    {validClientProvince ? (
                      <div className="child" style={{ color: "green" }}>
                        ✓
                      </div>
                    ) : (
                      <div className="child" style={{ color: "red" }}>
                        *
                      </div>
                    )}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => setClientProvince(e.target.value)}
                  >
                    <option>Select Province</option>
                    <option value="ab">Alberta</option>
                    <option value="bc">British Columbia</option>
                    <option value="mb">Manitoba</option>
                    <option value="nb">New Brunswick</option>
                    <option value="nl">Newfoundland and Labrador</option>
                    <option value="nt">Northwest Territories</option>
                    <option value="ns">Nova Scotia</option>
                    <option value="nu">Nunavut</option>
                    <option value="on">Ontario</option>
                    <option value="pe">Prince Edward Island</option>
                    <option value="qc">Quebec</option>
                    <option value="sk">Saskatchewan</option>
                    <option value="yt">Yukon</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3" controlId="SCI.postalCode">
                  <Form.Label>
                    Postal Code
                    {validClientPostalCode ? (
                      <div className="child" style={{ color: "green" }}>
                        ✓
                      </div>
                    ) : (
                      <div className="child" style={{ color: "red" }}>
                        *
                      </div>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="A1A 1A1"
                    onChange={(e) => setClientPostalCode(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <div className="checkoutMessage">
                Payment will be handled via Stripe's secure checkout portal
              </div>
              <ButtonCloseModal onClick={handleClose}>Close</ButtonCloseModal>
              {/*//TODO:add check for country eventually */}
              {validClientEmail &&
              validClientFName &&
              validClientLName &&
              validClientAddress &&
              validClientCity &&
              validClientProvince &&
              validClientPostalCode ? (
                <ButtonCheckout onClick={openStripe}>
                  Proceed To Checkout
                </ButtonCheckout>
              ) : (
                <ButtonCheckoutDisabled>
                  Proceed To Checkout
                </ButtonCheckoutDisabled>
              )}
            </Modal.Footer>
          </Modal>
        </>
      </div>
    </>
  );
}

export default Frame;
