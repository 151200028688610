import React from "react";
import "../index.css";

import customLayers from "../assets/images/ads/customLayers.jpg";
import designFeatures from "../assets/images/ads/designFeatures.jpg";
import keyboardPosture from "../assets/images/ads/keyboardPosture.jpg";
import madeToOrder from "../assets/images/ads/madeToOrder.jpg";
import makeUseOfYourThumbs from "../assets/images/ads/makeUseOfYourThumbs.jpg";
import animatedMechSwitches from "../assets/images/ads/animatedMechanicalSwitches.gif";

//consider using .webp images as they look the same as jpgs but are 20-30% smaller file size.
//need to find what is the best way to render images as i dont think cs6 can export in that format. . .
// i think we should also deploy before we consider doing any low res images as it might not be worth it
//but we will see.
const Ads = () => {
  return (
    <>
      <div className="parent">
        <div className="responsive">
          <img
            className="center"
            src={designFeatures}
            alt="Ergonomic design features are comfortable and reduce wrist pronation and hand fatigue"
            loading="lazy"
          ></img>
        </div>
      </div>
      <div className="parent">
        <div className="responsive">
          <img
            className="center"
            src={makeUseOfYourThumbs}
            alt="Use your thumbs to press keys on your keyboard instead of your pinky/little fingers"
            loading="lazy"
          ></img>
        </div>
      </div>
      <div className="parent">
        <div className="responsive">
          <img
            className="center"
            src={customLayers}
            alt="Custom layers allow more effective use of keyboard and speed up your workflow"
            loading="lazy"
          ></img>
        </div>
      </div>
      <div className="parent">
        <div className="responsive">
          <img
            className="center"
            src={keyboardPosture}
            alt="Dactyl Manuform allows you to sit with better posture in a more natural position"
            loading="lazy"
          ></img>
        </div>
      </div>
      <div className="parent">
        <div className="responsive">
          <img
            className="center"
            src={animatedMechSwitches}
            alt="Choose between brown tactile or blue clicky mechanical switches"
            loading="lazy"
          ></img>
        </div>
      </div>
      <div className="parent">
        <div className="responsive">
          <img
            className="center"
            src={madeToOrder}
            alt="Keyboards are custom made to order using 3D printing"
            loading="lazy"
          ></img>
        </div>
      </div>
    </>
  );
};

export default Ads;
