import React from "react";
import { Link } from "react-router-dom";

const ThankYouScreen = () => {
  return (
    <>
      <div className="Main">
        <div className="centerText">
          Thank you! Your purchase supports small independent makers!
        </div>
      </div>
      <div className="Main">
        <div className="centerText">
          <Link to="/home">HOME</Link>
        </div>
      </div>
    </>
  );
};

export default ThankYouScreen;
