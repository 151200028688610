import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import image1 from "../assets/images/carousel/BLACK_RED.jpg";
import image2 from "../assets/images/carousel/WHITE.jpg";
import image3 from "../assets/images/carousel/SKITTLES.jpg";
import image4 from "../assets/images/carousel/BLACK.jpg";
import image5 from "../assets/images/carousel/PORTAL.jpg";
import image6 from "../assets/images/carousel/BUBBLEGUM_GF.jpg";
import image7 from "../assets/images/carousel/SUPER_FAMACON.jpg";
import image8 from "../assets/images/carousel/FRAME_COLORS.jpg";
import image9 from "../assets/images/carousel/KEYCAP_COLORS.jpg";

const SlideShow = () => {
  return (
    <>
      <div className="parent">
        <div className="responsive">
          <div className="gallery">Gallery</div>
          <div className="center">
            <Carousel
              showArrows={true}
              showStatus={false}
              showIndicators={false}
              infiniteLoop={true}
              autoPlay={true}
              stopOnHover={true}
            >
              <div>
                <img src={image1} alt="Dactyl Manuform black with red" />
              </div>
              <div>
                <img src={image2} alt="Dactyl Manuform white" />
              </div>
              <div>
                <img src={image3} alt="Dactyl Manuform grey with raindow" />
              </div>
              <div>
                <img src={image4} alt="Dactyl Manuform black" />
              </div>
              <div>
                <img
                  src={image5}
                  alt="Dactyl Manuform off white with orange and cyan"
                />
              </div>
              <div>
                <img src={image6} alt="Dactyl Manuform white with pink" />
              </div>
              <div>
                <img
                  src={image7}
                  alt="Dactyl Manuform grey with famicon colors"
                />
              </div>
              <div>
                <img src={image8} alt="Dactyl Manuform frame colors" />
              </div>
              <div>
                <img src={image9} alt="key cap color selection" />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlideShow;
