import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ThankYouScreen from "./screens/ThankYouScreen";

function App() {
  return (
    <Router>
      <Header />
      <Route path="/checkoutcomplete" component={ThankYouScreen} />
      <Route path="/home" component={HomeScreen} />
      <Route path="/" component={HomeScreen} exact />
      <Footer />
    </Router>
  );
}

export default App;
