import React from "react";
import "../index.css";

const Table = () => {
  return (
    <>
      <div className="specsTableheader">Technical Specifications</div>
      <table className="specsTable">
        <tbody>
          <tr>
            <td className="cellLeft">N-Key Roll Over:</td>
            <td className="cellRight">Yes</td>
          </tr>
          <tr>
            <td className="cellColoredLeft">Anti Ghosting:</td>
            <td className="cellColoredRigth">Yes</td>
          </tr>
          <tr>
            <td className="cellLeft">Polling Rate:</td>
            <td className="cellRight">
              Customizable range from 125-1000 Hz, 8ms-1ms. Default will be set
              to 250 Hz, 4ms
            </td>
          </tr>
          <tr>
            <td className="cellColoredLeft">Firmware:</td>
            <td className="cellColoredRigth">QMK</td>
          </tr>
          <tr>
            <td className="cellLeft">Micro Controller:</td>
            <td className="cellRight">Arduino Micro</td>
          </tr>
          <tr>
            <td className="cellColoredLeft">Onboard Memory:</td>
            <td className="cellColoredRigth">
              32KB, for storing layers and macros
            </td>
          </tr>
          <tr>
            <td className="cellLeft">Reset/Flash Button:</td>
            <td className="cellRight">Yes</td>
          </tr>
          <tr>
            <td className="cellColoredLeft">Usb Cable:</td>
            <td className="cellColoredRigth">
              Detachable braided 2 meter usb to micro usb
            </td>
          </tr>
          <tr>
            <td className="cellLeft">Aux Cable:</td>
            <td className="cellRight">
              Detachable braided 1 meter male to male 3.5mm aux
            </td>
          </tr>
          <tr>
            <td className="cellColoredLeft">Key Caps:</td>
            <td className="cellColoredRigth">
              74 keys made from thick PBT plastic
            </td>
          </tr>
          <tr>
            <td className="cellLeft">Switches:</td>
            <td className="cellRight">
              Gateron mechanical switches, rated for a 50 million press lifespan
            </td>
          </tr>
          <tr>
            <td className="cellColoredLeft">Keyboard Length:</td>
            <td className="cellColoredRigth">215mm (8 ⁹⁄₁₆") </td>
          </tr>
          <tr>
            <td className="cellLeft">Keyboard Width:</td>
            <td className="cellRight">180mm (7 ⅛") per half</td>
          </tr>
          <tr>
            <td className="cellColoredLeft">Keyboard Heigth:</td>
            <td className="cellColoredRigth">75mm (3")</td>
          </tr>
          <tr>
            <td className="cellLeft">Frame Material:</td>
            <td className="cellRight">PLA+ printed with double thick walls</td>
          </tr>
          <tr>
            <td className="cellColoredLeft">Wrist Rest:</td>
            <td className="cellColoredRigth">Fabric gel pad</td>
          </tr>
          <tr>
            <td className="cellLeft">Feet:</td>
            <td className="cellRight">Silicone anti-slip pads</td>
          </tr>
          <tr>
            <td className="cellColoredLeft">Pakaging Includes:</td>
            <td className="cellColoredRigth">
              Braided usb and aux cables, extra homing keys, keycap puller
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Table;
