import React from "react";
import Frame from "../components/Frame";
import Ads from "../components/Ads";
import Faq from "../components/Faq";
import Table from "../components/Table";
import SlideShow from "../components/SlideShow";


const HomeScreen = () => {
  return (
    <>
      <Frame />
      <SlideShow />
      <Ads />
      <Table />
      <Faq />
    </>
  );
};

export default HomeScreen;


// run website locally: npm  run dev              