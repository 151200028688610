import React from "react";

//assets
import logo from "../assets/images/branding/logoMedium.svg";
import "../index.css";

const Header = () => {
  return (
    <>
      <img className="headerLogo" src={logo} alt="logo"></img>
      <div className="headerText">CypherDexKeyboards</div>
      <br></br>
      <div className="mvp" >This website is currently in the Minimum Viable Product state</div>
    </>
  );
};

export default Header;
