import React, { useState } from "react";
import Faq from "react-faq-component";

const data = {
  rows: [
    {
      title: "How long will it take to learn to type on this keyboard?",
      content: `It really depends how dedicated you are to learning and practising. We have found that on average it takes about 3 days to get back to your previous typing speed with your fingers, and potentially 3 more days to get proficient with your thumb keys and utilizing layers.`,
    },
    {
      title:
        "Will this keyboard help with the pain I experience in my fingers/hands/wrists while using a traditional keyboard?",
      content:
        "The Dactyl Manuforms by CypherDexKeyboards are built with comfort and ergonomics at the forefront and should help alleviate some if not all of the pain and discomfort you experience on a traditional keyboard. ",
    },
    {
      title: "What is Dactyl Manuform and what is CypherDexKeyboards?",
      content: `Dactyl Manuform is the name of the keyboard design. It is an open source design and there are many versions and variations. The Dactyl was first created by Matt Adereth, Jeff Gran then made the thumb design, Tom Short designed the outer walls, and many more contributors added to it.
      CypherDexKeyboards is the person/brand building the keyboards.
      `,
    },
    {
      title: "Do the keyboards have RGB leds?",
      content:
        "Unfortunately we do not offer an RGB version. Since these keyboards are hand soldered, including RGB would double the assembly time. However we offer a wide range of colored keycaps so you can make your keyboard look interesting and unique.",
    },
    {
      title: "Are these keyboards wired or wireless",
      content:
        "These keyboards are wired. They come with a 1 meter long 3.5mm braided aux cable, which connects to the two halves. As well as a 2 meter long usb A to micro usb cable, which connects to either half of the keyboard and your computer.",
    },
    {
      title: "Can I get a different brand of switches?",
      content:
        "Yes. Other types of switches will require a longer lead times as the switches will need to be ordered. Please contact cypherdexkeyboards@gmail.com for further information.",
    },
    {
      title: "Do you do custom orders?",
      content:
        "Yes we do. Custom orders will require a longer lead time as the custom parts will need to be ordered. The price will also very likely be higher, again due to having to order custom parts. Please contact cypherdexkeyboards@gmail.com for further information.",
    },
    {
      title: "Can I build one of these keyboards myself?",
      content:
        "Yes you can. The Dactyl Manuform is an open source design and instructions can be found online (A good starting point <a href='https://github.com/adereth/dactyl-keyboard' target='_blank' rel='noopener noreferrer'>here</a>). If you would like a DIY kit that comes with all parts you need, let us know as we are considering offering DIY kits in the future.",
    },
    {
      title: "What all comes in the box when I order a keyboard?",
      content:
        "The box will come with everything you need to plug in and use the keyboard. What’s included: the keyboard, a braided usb cable, a braided aux cable, extra homing keys, and a keycap puller.",
    },
    {
      title: "Are these keyboards good for gaming?",
      content:
        "Yes the keyboards are great for gaming. They are very comfortable and ergonomic, so your hands won’t get as sore or tired. You will also benefit from using your thumbs to hit more than just the space bar. This will require you to redo the key-bindings in the games you play though.",
    },
    {
      title: "What are these keyboards made out of?",
      content:
        "The keyboard frames are 3d printed out of PLA+. The keycaps are PBT plastic. They key switches are Gateron mechanical switches. The wrist rests are fabric covered gel pads.  And Arduino Micros act as the brains of the keyboard.",
    },
    {
      title: "Do these keyboards come in Colemak or Davorak layouts?",
      content:
        "Yes, since all the keycaps have the same height profile and the keyboard comes with extra homing keys you can easily rearrange the keys to a different layout. The keyboard can be reprogrammed and the layout is stored on the keyboard itself, so there is no need to have to fiddle around with the layout settings on the computers operating system. Another bonus is you can program a key to toggle between Qwerty and an alternative layout.",
    },
    {
      title: "What is the warranty on these keyboards?",
      content:
        "Each keyboard is tested to ensure proper functionality. There is a 3 month warranty on all components with the exception of the 3d printed frame and wrist rest. You will just need to cover the cost of shipping. Please contact cypherdexkeyboards@gmail.com for further information.",
    },
    {
      title: "What is your refund policy like?",
      content:
        "Since each keyboard is custom made and built to order, we unfortunately do not accept refunds. ",
    },
    {
      title: "Your website does not appear to be working properly.",
      content:
        "We would really appreciate if you let us know so that it can be fixed. Please contact cypherdexkeyboards@gmail.com",
    },
  ],
};

const styles = {
  bgColor: "white",
  titleTextColor: "black",
  rowTitleColor: "black",
  rowContentColor: "#666666",
  arrowColor: "#FF7900",
};

export default function App() {
  const [setRowsOption] = useState(null);

  return (
    <>
      <div className="specsTableheader">Frequently Asked Questions</div>
      <div className="faqCss">
        <Faq data={data} styles={styles} getRowOptions={setRowsOption} />
      </div>
    </>
  );
}
